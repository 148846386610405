import { hasRoles, roles as systemRoles } from "../utils/utils";

/* eslint-disable */
export const menus = (roles = [],addMenus = []) =>  [...addMenus,...[
    {
        key: 'admin.dashboard',
        title: 'Dashboard',
        icon: "home",
        isFontAwesome: false,
        canEnter: hasRoles(roles, [systemRoles.admin.ROLE_ADMIN, systemRoles.admin.ROLE_USER]),
    },
    {
        key: 'admin.reports.monitoring',
        title: 'Monitoreo',
        icon: 'audit',
        isFontAwesome: false,
        canEnter: hasRoles(roles, [systemRoles.admin.ROLE_ADMIN, systemRoles.admin.ROLE_MONITORING_REPORT]),
    },
    {
        key: 'admin.manage.lottery',
        title: 'Administrar Loteria',
        icon: ['fas', 'ticket-alt'],
        isFontAwesome: true,
        canEnter: hasRoles(roles, [systemRoles.admin.ROLE_ADMIN, systemRoles.admin.ROLE_LOTTERIES, systemRoles.admin.ROLE_RAFFLES, systemRoles.admin.ROLE_BLOCKS]),
        children: [
            {
                key: 'admin.lotteries',
                title: 'Loterias',
                isFontAwesome: false,
                canEnter: hasRoles(roles, [systemRoles.admin.ROLE_ADMIN, systemRoles.admin.ROLE_LOTTERIES]),
            },
            {
                key: 'admin.raffles',
                title: 'Sorteos',
                isFontAwesome: false,
                canEnter: hasRoles(roles, [systemRoles.admin.ROLE_ADMIN, systemRoles.admin.ROLE_RAFFLES]),
            },
            {
                key: 'admin.blocks',
                title: 'Bloqueos',
                isFontAwesome: false,
                canEnter: hasRoles(roles, [systemRoles.admin.ROLE_ADMIN, systemRoles.admin.ROLE_BLOCKS]),
            },
            {
                key: 'admin.lotteries-closing-schedules',
                title: 'Programación Cierre Loterias',
                isFontAwesome: false,
                canEnter: hasRoles(roles, [systemRoles.admin.ROLE_ADMIN, systemRoles.admin.ROLE_LOTTERIES_CLOSING_SCHEDULE]),
            },
        ].filter(item => item.canEnter)
    },
    {
        key: 'admin.orders',
        title: 'Tickets',
        icon: ['fas', 'ticket-alt'],
        isFontAwesome: true,
        canEnter: hasRoles(roles, [systemRoles.admin.ROLE_ADMIN, systemRoles.admin.ROLE_ORDERS]),
    },
    {
        key: 'admin.transactions',
        title: 'Transacciones',
        icon: ['fas', 'arrow-right-arrow-left'],
        isFontAwesome: true,
        canEnter: hasRoles(roles, [systemRoles.admin.ROLE_ADMIN, systemRoles.admin.ROLE_TRANSACTIONS]),
    },
    {
        key: 'admin.balance-movements',
        title: 'Movimientos de Balance',
        icon: ['fas', 'money-bill-wave'],
        isFontAwesome: true,
        canEnter: hasRoles(roles, [systemRoles.admin.ROLE_ADMIN, systemRoles.admin.ROLE_BALANCE_MOVEMENTS]),
    },
    {
        key: 'admin.commissions',
        title: 'Comisiones',
        icon: ['fas', 'hand-holding-dollar'],
        isFontAwesome: true,
        canEnter: hasRoles(roles, [systemRoles.admin.ROLE_ADMIN, systemRoles.admin.ROLE_COMMISSIONS_GENERATE]),
    },
    {
        key: 'admin.users',
        title: 'Usuarios',
        icon: ['fas', 'users'],
        isFontAwesome: true,
        canEnter: hasRoles(roles, [systemRoles.admin.ROLE_ADMIN, systemRoles.admin.ROLE_USERS]),
    },
    {
        key: 'admin.config',
        title: 'Configuración',
        icon: "setting",
        isFontAwesome: false,
        canEnter: hasRoles(roles, [systemRoles.admin.ROLE_ADMIN, systemRoles.admin.ROLE_USERS]),
        children: [
            {
                key: 'admin.commission-profiles',
                title: 'Perfiles de Comisiones',
                isFontAwesome: false,
                canEnter: hasRoles(roles, [systemRoles.admin.ROLE_ADMIN, systemRoles.admin.ROLE_USER_COMMISSION_PROFILES]),
            },
            {
                key: 'admin.transaction-profiles',
                title: 'Perfiles de Transacciones',
                isFontAwesome: false,
                canEnter: hasRoles(roles, [systemRoles.admin.ROLE_ADMIN, systemRoles.admin.ROLE_USER_TRANSACTION_PROFILES]),
            },
            {
                key: 'admin.admins',
                title: 'Usuarios (Admin)',
                isFontAwesome: false,
                canEnter: hasRoles(roles, [systemRoles.admin.ROLE_ADMIN, systemRoles.admin.ROLE_ADMINS]),
            },
            {
                key: 'admin.system-config',
                title: 'Configuración del Sistema',
                isFontAwesome: false,
                canEnter: hasRoles(roles, [systemRoles.admin.ROLE_ADMIN]),
            }
        ].filter(item => item.canEnter)
    },
]]
.filter(menu => menu.canEnter)
.filter((item = {}) => (item.hasOwnProperty('children') && item.children.length > 0) || !item.hasOwnProperty('children'))
;