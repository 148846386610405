import * as actions from "../../utils/actions";

export default {
  namespaced: true,
  name: "layout",
  state: {
    collapsed: false,
    openKeys: [],
    selectedKeys: [],
    breakPoint: false,
    screenWidth: 0,
  },
  mutations: {
    [actions.UPDATE_LAYOUT](state, payload) {
      Object.assign(state, payload);
    },
  },
  actions: {
    updateLayout({ commit }, payload) {
      commit(actions.UPDATE_LAYOUT, payload);
    },
  },
  getters: {
    getLayoutDataStore: (state) => {
      return state;
    },
  },
};
